<template>
  <div>
    <ClientOnly>
      <CdeCard
        :max-width="smAndDown ? '100vw' : '300'"
        class="mx-auto py-3 py-md-4 px-0 pl-md-6 pr-md-3 counter-wrap"
        :class="{ 'rounded-lg': !smAndDown }"
        :rounded="!smAndDown">
        <v-row>
          <v-col
            cols="7"
            sm="8"
            md="12"
            class="font-weight-bold pr-0 pl-md-1 pr-md-3"
            :class="{ 'short-height': smAndDown }"
            align-self="center">
            <span>
              {{ `${t('delivery_by_tomorrow')} ${tomorrowDate.long}` }}
            </span>
            {{ t('possible_until') }}
          </v-col>
          <v-col cols="5" sm="4" md="12" class="pl-0 px-md-0 py-0 pb-md-3">
            <v-row class="countdown mb-0 mb-sm-5 mx-0 px-0">
              <v-col class="text-center px-0 px-sm-1 counter-card" cols="4" sm="3">
                <CountDownCard class="position-absolute pt-1" width="68px">
                  <p class="text-h4 font-weight-bold">
                    {{ hours.toString().padStart(2, '0') }}
                  </p>
                  <span class="label text-caption">{{ t('hours') }}</span>
                </CountDownCard>
                <CountDownCard
                  :class="[
                    'number rounded-b-0 elevation-0 border-b-sm border-surface pt-1',
                    hoursChanged ? 'animate' : '',
                  ]"
                  width="68px">
                  <p class="text-h4 font-weight-bold">
                    {{ hours.toString().padStart(2, '0') }}
                  </p>
                </CountDownCard>
              </v-col>
              <v-col v-if="smAndUp" cols="1" class="pb-0 pr-0 pl-3 pt-8">
                <div class="circle bg-secondary" />
                <div class="circle bg-secondary mt-2" />
              </v-col>
              <v-col class="text-center px-0 px-sm-1 counter-card" cols="4" sm="3">
                <CountDownCard class="position-absolute pt-1" width="68px">
                  <p class="text-h4 font-weight-bold">
                    {{ minutes.toString().padStart(2, '0') }}
                  </p>
                  <span class="label text-caption">{{ t('minutes') }}</span>
                </CountDownCard>
                <CountDownCard
                  :class="[
                    'number rounded-b-0 elevation-0 border-b-sm border-surface pt-1',
                    minutesChanged ? 'animate' : '',
                  ]"
                  width="68px">
                  <p class="text-h4 font-weight-bold">
                    {{ minutes.toString().padStart(2, '0') }}
                  </p>
                </CountDownCard>
              </v-col>
              <v-col v-if="smAndUp" cols="1" class="pb-0 pr-0 pl-3 pt-8">
                <div class="circle bg-secondary" />
                <div class="circle bg-secondary mt-2" />
              </v-col>
              <v-col class="text-center px-0 px-sm-1 counter-card" cols="4" sm="3">
                <CountDownCard class="position-absolute pt-1" width="68px">
                  <p class="text-h4 font-weight-bold">
                    {{ seconds.toString().padStart(2, '0') }}
                  </p>
                  <span class="label text-caption">{{ t('seconds') }}</span>
                </CountDownCard>
                <CountDownCard
                  :class="[
                    'number rounded-b-0 elevation-0 border-b-sm border-surface pt-1',
                    secondsChanged ? 'animate' : '',
                  ]"
                  width="68px">
                  <p class="text-h4 font-weight-bold">
                    {{ seconds.toString().padStart(2, '0') }}
                  </p>
                </CountDownCard>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </CdeCard>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue'
import { useDisplay } from 'vuetify'
const { smAndDown, smAndUp } = useDisplay()
const { t } = useI18n()
const { getAvailableTomorrowDate, createNewGermanDate } = useDateUtils()
const secondsChanged = ref(false)
const minutesChanged = ref(false)
const hoursChanged = ref(false)
const availableTomorrowDate = ref(new Date())

const timerCount = ref(getSecondsUntilNoon())

const hours = computed(() => Math.floor(timerCount.value / 3600))
const minutes = computed(() => Math.floor((timerCount.value % 3600) / 60))
const seconds = computed(() => timerCount.value % 60)
const emits = defineEmits(['nowIsNoon'])

onNuxtReady(() => {
  getAvailableTomorrowDate().then(date => {
    availableTomorrowDate.value = date
  })
})
const tomorrowDate = computed(() => {
  const tomorrow = availableTomorrowDate.value.toLocaleString('de-DE', {
    weekday: 'long',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
  const tomorrowDateOnly = availableTomorrowDate.value.toLocaleString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
  return { long: tomorrow, dateOnly: tomorrowDateOnly }
})

function getSecondsUntilNoon() {
  const now = createNewGermanDate()
  const noon = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 12, 0, 0) //Uhrzeit bis wann die Bestellung aufgegeben werden muss
  const secondsUntilNoon = Math.floor((noon.getTime() - now.getTime()) / 1000)
  return secondsUntilNoon > 0 ? secondsUntilNoon : 0
}

watchEffect(() => {
  if (timerCount.value > 0) {
    setTimeout(() => {
      timerCount.value--
    }, 1000)
  } else {
    emits('nowIsNoon')
  }
})

watch(seconds, () => {
  secondsChanged.value = true
  setTimeout(() => {
    secondsChanged.value = false
  }, 300)
})

watch(minutes, () => {
  minutesChanged.value = true
  setTimeout(() => {
    minutesChanged.value = false
  }, 300)
})
watch(hours, () => {
  hoursChanged.value = true
  setTimeout(() => {
    hoursChanged.value = false
  }, 300)
})
</script>

<style scoped lang="scss">
.countdown .number {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform-origin: left bottom 0px;
  transition: all 0s ease 0s;
  line-height: 32px;
}
.countdown .number.animate {
  filter: brightness(0);
  transform: matrix3d(1, 0, 0, 0, 0, -0.5, 0, 0, 0, 0, -0.5, 0, 0, 0, 0, 1);
  transition: all 0.5s ease 0s;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.short-height {
  line-height: 19px;
}
@media (max-width: 787px) {
  .counter-card {
    transform: scale(0.9);
  }
}
@media (max-width: 725px) {
  .counter-card {
    transform: scale(0.75);
  }
}
@media (max-width: 465px) {
  .counter-card {
    transform: scale(0.7);
  }
}
@media (max-width: 375px) {
  .counter-card {
    transform: scale(0.68);
  }
}
</style>
